import { EndpointEnum } from "enums/endpoint.enum";
import { isResponseOk } from "helpers/isResponseOk";
import { PROJECT_NAME } from "constants/env.const";
import { ILocation, LocationType, LostPhoneInfo } from "interfaces/location/location.interface";
import { deleteApi, get, post } from "./http.client";

type CreateLocation = Required<Pick<ILocation, "name" | "type">>;

interface CreateFindDevice extends CreateLocation {
    type: LocationType.FIND_DEVICE;
}

interface CreateLostPhone extends CreateLocation {
    type: LocationType.LOST_PHONE;
    lostPhoneInfo: LostPhoneInfo;
}

interface LocationsQuery extends Partial<Pick<ILocation, "type">> {
    status?: string;
}

interface SendSupportTicket {
    email: string;
    name: string;
    message: string;
    subject: string;
}

export const getLocationLink = async (data: CreateFindDevice | CreateLostPhone) => {
    return post(`${EndpointEnum.LOCATIONS}/link`, data);
};

export type UpdateLocationProps = Required<Pick<ILocation, "latitude" | "longitude" | "target" | "details">>;

export const updateLocation = async (id: string, data: UpdateLocationProps) => {
    return post<ILocation>(`${EndpointEnum.LOCATIONS}/${id}`, data);
};

export const renameLocation = async (id: string, name: string) => {
    return post<ILocation>(`${EndpointEnum.LOCATIONS}/${id}/rename`, { name });
};

export const deleteLocation = async (id: string) => {
    return deleteApi(`${EndpointEnum.LOCATIONS}/${id}`);
};

export const getLocations = async (query?: LocationsQuery) => {
    try {
        const response = await get(`${EndpointEnum.LOCATIONS}`, {
            type: query?.type,
            status: query?.status,
        });
        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (e) {
        console.error("error:", e);
    }
};

export interface SendLocationSmsProps {
    locationId: string;
    language: string;
    flowOuter: boolean;
}

export const sendLocationSms = async ({ locationId, language, flowOuter }: SendLocationSmsProps) => {
    return post(`${EndpointEnum.LOCATIONS}/${locationId}/sms`, {
        project: PROJECT_NAME,
        language,
        flowOuter,
    });
};

export const sendSupportTicket = async ({ email, name, message, subject }: SendSupportTicket) => {
    return post(`${EndpointEnum.SUPPORT_TICKET}`, {
        email,
        name,
        message,
        subject,
    });
};
