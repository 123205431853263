import { Link, useBeforeUnload, useLocation, useNavigate } from "react-router-dom";
import { WidthContainer } from "components/shared";
import { Stack, Typography, styled } from "@mui/material";
import { themeColors } from "constants/colors.const";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import { useTranslation } from "react-i18next";
import { useAppData } from "providers/RootStoreProvider";
import { AppRoutes } from "enums/routes.enum";
import { observer } from "mobx-react-lite";

const StyledFooter = styled("footer")(() => ({
    background: themeColors.background.secondary,
    padding: "40px 0",
}));

export const Footer = observer(() => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isMobile } = useDeviceDetect();
    const { t } = useTranslation();
    const { setIsAccountRedirectDisabled, flowOuter } = useAppData();
    const isAccountPage = pathname === AppRoutes.ACCOUNT;

    const handleLegalRedirect = async (event, path: string) => {
        if (isAccountPage) {
            event.preventDefault();
            await setIsAccountRedirectDisabled(false);
            navigate(path);
        }
    };

    useBeforeUnload(() => {
        if (isAccountPage) {
            setIsAccountRedirectDisabled(true);
        }
    });

    return (
        <StyledFooter id="contacts">
            <WidthContainer>
                <Stack justifyContent="space-between" gap={isMobile ? "56px" : undefined} direction={isMobile ? "column" : "row"}>
                    <Stack direction="column" sx={{ maxWidth: isMobile ? "100%" : "70%" }}>
                        <Link to="/" style={{ marginBottom: "44px" }}>
                            <img src={"/icons/logo-white.svg"} alt="Geomobile" />
                        </Link>
                        <Typography color={themeColors.text.grey} variant="body2Regular" sx={{ marginBottom: "24px" }}>
                            Webpeak OÜ (Ltd), 16904672,
                            <br />
                            Harju maakond, Tallinn, Lasnamäe linnaosa, Valukoja tn 8/2, 11415,
                            {isMobile && <br />}
                            <Link to="mailto:support@geomobile.cc" style={!isMobile ? { marginLeft: ".5rem" } : undefined}>
                                support@geomobile.cc
                            </Link>
                        </Typography>
                        <Typography color={themeColors.text.grey} variant="title1Bold" sx={{ marginBottom: "20px" }}>
                            {t("Secure online payments")}:
                        </Typography>
                        <Stack direction="row" gap="16px">
                            <img src="/icons/visa-badge.svg" alt="visa" />
                            <img src="/icons/mastercard-badge.svg" alt="mastercard" />
                            {flowOuter && (
                                <>
                                    <img src="/icons/maestro.png" alt="maestro" style={{ maxWidth: "64px" }} />
                                    <img src="/icons/ssl.png" alt="ssl" style={{ maxWidth: "64px" }} />
                                </>
                            )}
                        </Stack>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant="title1Bold" color={themeColors.brandColors.brandLightgrey} sx={{ marginBottom: "22px" }}>
                            {t("Legal info")}:
                        </Typography>
                        <Link to={AppRoutes.COOKIE_POLICY} style={{ marginBottom: "12px" }} onClick={(e) => handleLegalRedirect(e, AppRoutes.COOKIE_POLICY)}>
                            <Typography variant="body2Regular" color={themeColors.text.grey}>
                                {t("Cookie Policy")}
                            </Typography>
                        </Link>
                        <Link to={AppRoutes.PRIVACY_POLICY} style={{ marginBottom: "12px" }} onClick={(e) => handleLegalRedirect(e, AppRoutes.PRIVACY_POLICY)}>
                            <Typography variant="body2Regular" color={themeColors.text.grey}>
                                {t("Privacy Policy")}
                            </Typography>
                        </Link>
                        <Link to={AppRoutes.TERMS_OF_USE} style={{ marginBottom: "12px" }} onClick={(e) => handleLegalRedirect(e, AppRoutes.TERMS_OF_USE)}>
                            <Typography variant="body2Regular" color={themeColors.text.grey}>
                                {t("Terms of Use")}
                            </Typography>
                        </Link>
                    </Stack>
                </Stack>
            </WidthContainer>
            <Typography align="center" variant="body2Regular" color={themeColors.text.white} display="block" mt="56px">
                {`Copyright © ${new Date().getFullYear()} geomobile.cc`}
            </Typography>
        </StyledFooter>
    );
});
