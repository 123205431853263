import { FC, useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useAppData } from "providers/RootStoreProvider";
import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material";
import GoogleMap from "components/Map/GoogleMap";
import { ILocation, LocationStatus } from "interfaces/location/location.interface";
import { getPhoneCarrier } from "http-client/phone.client";

interface HistoryMapProps {
    location: ILocation | null;
}

const HistoryMap: FC<HistoryMapProps> = observer(({ location }) => {
    const { t } = useTranslation();
    const [position, setPosition] = useState<null | Record<"latitude" | "longitude", string>>(null);
    const { ipInfo } = useAppData();
    const cityPositionCache = useRef(new Map());

    const isMarkerVisible = !!location?.status && [LocationStatus.API_LOCATED, LocationStatus.GPS_LOCATED].includes(location.status);

    const setPositionByNumber = useCallback(async (name: string) => {
        if (name) {
            let coordinates = position;

            if (cityPositionCache.current.has(name)) {
                coordinates = cityPositionCache.current.get(name);
            } else {
                const data = await getPhoneCarrier(name);
                if (data?.coordinates) {
                    coordinates = {
                        latitude: data.coordinates.lat.toString(),
                        longitude: data.coordinates.lng.toString(),
                    };
                    cityPositionCache.current.set(name, coordinates);
                }
            }

            setPosition(coordinates);
        }
    }, []);

    useEffect(() => {
        if (location && location?.details?.searchNumber) {
            if (location?.latitude && location.longitude) {
                setPosition({
                    latitude: location?.latitude,
                    longitude: location.longitude,
                });
                return;
            } else {
                setPositionByNumber(location.details.searchNumber);
                return;
            }
        }

        if (!location) {
            const initialLocation = ipInfo?.loc.split(",");

            if (initialLocation) {
                setPosition({
                    latitude: initialLocation?.[0],
                    longitude: initialLocation?.[1],
                });
                return;
            }
        }
    }, [location, ipInfo]);

    return (
        <Box height="fit-content" component="div" width="100%" borderRadius="24px" bgcolor="background.lightAccent" position="sticky" top={100}>
            <Typography variant="dmSansH5Regular" component="p" padding="16px">
                {t("Map view")}
            </Typography>
            <Divider sx={(theme) => ({ borderColor: theme.palette.background.default, borderWidth: "2px" })} />
            <Stack height="622px" padding="16px">
                {!position ? (
                    <Skeleton variant="rectangular" width="100%" height="100%" sx={{ borderRadius: "18px", background: "#242f3e" }} />
                ) : (
                    <GoogleMap location={position as ILocation} borderRadius="18px" height="100%" withMarker={isMarkerVisible} />
                )}
            </Stack>
        </Box>
    );
});

export default HistoryMap;
